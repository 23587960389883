import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Istory, StoryState } from "../../types/types";

// Define the initial state interface and value
const initialState: StoryState = {
  story: [],
};

// Create a Redux slice for managing stories
const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    setStory: (state, action: PayloadAction<Istory[]>) => {
      state.story = action.payload;
    },
    deleteStory: (state, action: PayloadAction<string>) => {
      // Filter out the deleted story from the state
      state.story.forEach((user) => {
        user.stories = user.stories.filter(
          (story) => story.storyImg !== action.payload
        );
      });
    },
    addStory: (state, action: PayloadAction<Istory>) => {
      state.story.unshift(action.payload);
    },
  },
});

// Export the action creator generated by the slice
export const { setStory, deleteStory, addStory } = storySlice.actions;

// Export the reducer function generated by the slice
export default storySlice.reducer;
