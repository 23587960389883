import React from "react";
import EditProfile from "../../components/Profile/EditProfile";

function CompleteProfile() {
  return (
    <div>
      <EditProfile />
    </div>
  );
}

export default CompleteProfile;
