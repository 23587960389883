import React from "react";
import AdminUsermanagement from "../../components/Users/AdminEditUser";

function AdminEditUser() {
  return (
    <div>
      <AdminUsermanagement />
    </div>
  );
}

export default AdminEditUser;
